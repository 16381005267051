//  icons
import {
  FiInstagram,
  FiGithub,
  FiMail,
  FiMapPin,
  FiLinkedin,
  FiShoppingCart,
} from "react-icons/fi";

import { BiSupport } from "react-icons/bi";

import { CgWebsite, CgTranscript } from "react-icons/cg";

import { SiFreelancer, SiUpwork } from "react-icons/si";

// companies icons
import FreelancerBrandIcon from "./assets/img/brands/freelancer.png";
// import UpworkBrandIcon from "./assets/img/brands/upwork.png";
// import FiverBrandIcon from "./assets/img/brands/fiverr.png";
// import BehanceBrandIcon from "./assets/img/brands/behance.png";
// import DribbbleBrandIcon from "./assets/img/brands/dribbble.png";

// projects images
import Project1 from "./assets/img/projects/1.png";
import Project2 from "./assets/img/projects/2.png";
import Project3 from "./assets/img/projects/3.png";
import Project4 from "./assets/img/projects/4.png";
import Project5 from "./assets/img/projects/5.png";
import Project6 from "./assets/img/projects/6.png";
import Project7 from "./assets/img/projects/7.png";
import Project8 from "./assets/img/projects/8.png";
import Project9 from "./assets/img/projects/9.png";
import Project10 from "./assets/img/projects/10.png";
import Project11 from "./assets/img/projects/11.png";
import Project12 from "./assets/img/projects/12.png";
import Project13 from "./assets/img/projects/13.png";
import Project14 from "./assets/img/projects/14.png";
import Project15 from "./assets/img/projects/15.png";
import Project16 from "./assets/img/projects/16.png";
import Project17 from "./assets/img/projects/17.png";
import Project18 from "./assets/img/projects/18.png";
import Project19 from "./assets/img/projects/19.png";
import Project20 from "./assets/img/projects/20.png";
import Project21 from "./assets/img/projects/21.png";
import Project22 from "./assets/img/projects/22.png";
import Project23 from "./assets/img/projects/23.png";
import Project24 from "./assets/img/projects/24.png";

// skills images
import SkillImg1 from "./assets/img/skills/html.png";
import SkillImg2 from "./assets/img/skills/css-3.png";
import SkillImg3 from "./assets/img/skills/js.png";
import SkillImg4 from "./assets/img/skills/physics.png";
import SkillImg5 from "./assets/img/skills/tailwind.png";
import SkillImg6 from "./assets/img/skills/git.png";
import SkillImg7 from "./assets/img/skills/wordpress.png";
import SkillImg8 from "./assets/img/skills/elementor.png";

// testimonial images
import ajmalImg from "./assets/img/testimonials/ajmal_m.png";
import akhilImg from "./assets/img/testimonials/akheel_reddy.png";
import vishalImg from "./assets/img/testimonials/vishal.png";
import nishantImg from "./assets/img/testimonials/nishant.jpg";
import techinfodivenImg from "./assets/img/testimonials/techinfodriven.png";
import fransiscoImg from "./assets/img/testimonials/francisco_p.png";

// navigation
export const navigation = [
  {
    name: "home",
    href: "home",
  },
  {
    name: "about",
    href: "about",
  },
  {
    name: "portfolio",
    href: "portfolio",
  },
  {
    name: "services",
    href: "services",
  },
  {
    name: "testimonials",
    href: "testimonials",
  },
  {
    name: "contact",
    href: "contact",
  },
];

// social
export const social = [
  {
    icon: <FiLinkedin />,
    href: "https://www.linkedin.com/in/ravikantkr/",
  },
  {
    icon: <FiGithub />,
    href: "https://github.com/ravikntkr",
  },
  {
    icon: <SiFreelancer />,
    href: "https://www.freelancer.in/u/ravikant1999",
  },
  {
    icon: <SiUpwork />,
    href: "https://www.freelancer.in/u/ravikant1999",
  },
  {
    icon: <FiMail />,
    href: "mailto:ravikntkr@gmail.com",
  },
  {
    icon: <FiInstagram />,
    href: "https://www.instagram.com/rviknt.kr/",
  },
];

// companies
export const brands = [
  {
    img: FreelancerBrandIcon,
    href: "",
  },
  // {
  //   img: UpworkBrandIcon,
  //   href: "",
  // },
  // {
  //   img: FiverBrandIcon,
  //   href: "",
  // },
  // {
  //   img: BehanceBrandIcon,
  //   href: "",
  // },
  // {
  //   img: DribbbleBrandIcon,
  //   href: "",
  // },
];

// projects
export const projectsData = [
  {
    id: "1",
    image: Project1,
    name: "Beats Landing Page",
    category: "HTML/CSS",
    github: "https://github.com/ravikntkr/beatslandingpage",
    liveLink: "https://beatslandingpage.netlify.app/",
  },
  {
    id: "2",
    image: Project2,
    name: "Data Analytic Landing Page",
    category: "HTML/CSS",
    github: "https://github.com/ravikntkr/DataAnalyticsLandingPage",
    liveLink: "https://dataanalyticslandingpage.netlify.app/",
  },
  {
    id: "3",
    image: Project3,
    name: "Hosting Landing Page",
    category: "HTML/CSS",
    github: "https://github.com/ravikntkr/hosting-site-landing-page",
    liveLink: "https://hostingsitelandingpage.netlify.app/",
  },
  {
    id: "4",
    image: Project4,
    name: "Credit Card Landing Page",
    category: "HTML/CSS",
    github: "https://github.com/ravikntkr/creditcardlandingpage",
    liveLink: "https://creditcardlandingpge.netlify.app/",
  },
  {
    id: "5",
    image: Project5,
    name: "Movie Review Web App",
    category: "React",
    github: "https://github.com/ravikntkr/moviereview",
    liveLink: "https://indmovie.netlify.app/",
  },
  {
    id: "6",
    image: Project6,
    name: "Recipe Web App",
    category: "React",
    github: "https://github.com/ravikntkr/homechef",
    liveLink: "https://homechefrecpie.netlify.app/",
  },
  {
    id: "7",
    image: Project7,
    name: "Calculator Web App",
    category: "React",
    github: "https://github.com/ravikntkr/calculator",
    liveLink: "https://reactcalculator2.netlify.app/",
  },
  {
    id: "8",
    image: Project8,
    name: "Blog Post",
    category: "Javascript",
    github: "https://github.com/ravikntkr/Blogwebapp",
    liveLink: "https://fakepost.netlify.app/",
  },
  {
    id: "9",
    image: Project9,
    name: "To-do Website",
    category: "Javascript",
    github: "https://github.com/ravikntkr/todo",
    liveLink: "https://addtodayplan.netlify.app/",
  },
  {
    id: "10",
    image: Project10,
    name: "Ineuron.ai Home Page",
    category: "HTML/CSS",
    github: "https://github.com/ravikntkr/inuronHomePageClone",
    liveLink: "https://ineuronclone.netlify.app/",
  },
  {
    id: "11",
    image: Project11,
    name: "Portfolio Web App",
    category: "React",
    github: "#",
    liveLink: "#",
  },
  {
    id: "12",
    image: Project12,
    name: "Portfolio Website",
    category: "HTML/CSS",
    github: "https://github.com/ravikntkr/DeveloperLandingpage",
    liveLink: "https://ravikntkr.netlify.app/",
  },
  {
    id: "13",
    image: Project13,
    name: "Realestate Landing Page",
    category: "HTML/CSS",
    github: "https://github.com/ravikntkr/realestatelandingpage",
    liveLink: "https://realestatelandingpage.netlify.app/",
  },
  {
    id: "14",
    image: Project14,
    name: "Games Landing Page",
    category: "HTML/CSS",
    github: "https://github.com/ravikntkr/gamelandingpage",
    liveLink: "https://gameinglandingpage.netlify.app/",
  },
  {
    id: "15",
    image: Project20,
    name: "Deck Builder",
    category: "wordpress",
    github: "",
    liveLink: "https://ankenydeckspecialist.com/",
  },
  {
    id: "16",
    image: Project21,
    name: "Home Construction",
    category: "wordpress",
    github: "",
    liveLink: "https://reviveremodel.com/",
  },
  {
    id: "17",
    image: Project22,
    name: "Construction Company Website",
    category: "wordpress",
    github: "",
    liveLink: "https://a1basementfinishing.com/",
  },
  {
    id: "18",
    image: Project23,
    name: "E-Commerce Website",
    category: "wordpress",
    github: "",
    liveLink: "https://thecrystalstore.in/",
  },
  {
    id: "19",
    image: Project24,
    name: "Organisation Website",
    category: "wordpress",
    github: "",
    liveLink: "http://bramhanand.org/",
  },
  {
    id: "20",
    image: Project15,
    name: "Weather App",
    category: "javascript",
    github: "https://github.com/ravikntkr/weatherapp",
    liveLink: "https://weatherliveupdate.netlify.app/",
  },
  {
    id: "21",
    image: Project16,
    name: "Groupick Website",
    category: "wordpress",
    github: "",
    liveLink: "https://groupick.in/",
  },
  {
    id: "22",
    image: Project17,
    name: "IT Company Website",
    category: "wordpress",
    github: "",
    liveLink: "https://techinfodriven.com/",
  },
  {
    id: "23",
    image: Project18,
    name: "E-commerce Website",
    category: "wordpress",
    github: "",
    liveLink: "https://phone-werk.de/",
  },
  {
    id: "24",
    image: Project19,
    name: "Stone Supplier",
    category: "wordpress",
    github: "",
    liveLink: "https://www.baasarstone.com.au/",
  },
];

// projects
export const projectsNav = [
  {
    name: "all",
  },
  {
    name: "Wordpress",
  },
  {
    name: "HTML/CSS",
  },
  {
    name: "Javascript",
  },
  {
    name: "React",
  },
];

// skill
export const skills = [
  {
    image: SkillImg1,
  },
  {
    image: SkillImg2,
  },
  {
    image: SkillImg3,
  },
  {
    image: SkillImg4,
  },
  {
    image: SkillImg5,
  },
  {
    image: SkillImg6,
  },
  {
    image: SkillImg7,
  },
  {
    image: SkillImg8,
  },
];

// services
export const services = [
  {
    icon: <CgWebsite />,
    name: "Website Design",
    description:
      "Creative web design that stands out. Eye-catching visuals and intuitive user experiences.",
  },
  {
    icon: <CgTranscript />,
    name: "Website Redesign",
    description:
      "Website redesign services to give clients' existing websites a fresh and modern look.",
  },
  {
    icon: <FiShoppingCart />,
    name: "E-commerce Website",
    description:
      "Creating e-commerce websites using WordPress, and ensuring a seamless shopping experience for customers.",
  },
  {
    icon: <BiSupport />,
    name: "Website Maintenance",
    description:
      "Ensuring peak website performance with reliable maintenance and dedicated support.",
  },
];

// testimonials
export const testimonials = [
  {
    authorImg: fransiscoImg,
    authorText:
      "He was a very respectfull proffesional, did the job on time and with excellent communications. All reviews were done satisfactory. I highly recommend his services as web developer",
    authorName: "Francisco P.",
    // authorPosition: "Head of Design, Google",
  },
  {
    authorImg: vishalImg,
    authorText:
      "Great job done by Ravikant. Exceptional end product, always pleased by his communication skill and the right attitude.Did few projects with him and had delivered awesome work.Would highly recommend him.",
    authorName: "Vishal P.",
    authorPosition: "Ankeny Deck Specialist",
  },
  {
    authorImg: ajmalImg,
    authorText:
      "Er kann alles was sie vorstellen umsetzen sehr gut gearbeitet gerne wieder",
    authorName: "Ajmal M.",
    authorPosition: "Service4mobile, CEO",
  },
  {
    authorImg: akhilImg,
    authorText:
      "It was pleasure working with the team, always ready to adapt to changes on the design. As an engineer working in the tech industry for the past 8-9 years, i'd recommend them for the project.",
    authorName: "Akhil Reddy P.",
    // authorPosition: "Head of Design, Google",
  },
  {
    authorImg: techinfodivenImg,
    authorText:
      "He is the professional and very much dedicated towards his work, he stood up on my expectations. Its was pleasure working with him, would be glad to work with him in future.",
    authorName: "Syed N.",
    authorPosition: "Techinfodriven, CEO",
  },
  {
    authorImg: nishantImg,
    authorText:
      "Excellent and timely work, he is being very professional. highly recommended.",
    authorName: "Nishant S.",
    authorPosition: "Avid Web Solution, CEO",
  },
];

// contact
export const contact = [
  {
    icon: <FiMail />,
    title: "Have a question?",
    subtitle: "I am here to help you.",
    description: "Email me at ravikntkr@gmail.com",
  },
  {
    icon: <FiMapPin />,
    title: "Current Location",
    subtitle: "India",
    description: "Serving clients worldwide",
  },
];
