import React from "react";

// Import components
// import Project from "./Project";
import Projects from "./Projects";
// import { contact } from "../data";

const Portfolio = () => {
  return (
    <section id="portfolio" className="section bg-primary min-h-[1200px]">
      <div className="container mx-auto">
        <div className="flex flex-col items-center text-center">
          <h2 className="section-title text-secondary text-3xl lg:text-4xl font-medium lg:font-extrabold mb-3 before:content-portfolio relative before:absolute before:opacity-30 before:top-[-2rem] before:-left-3/4 before:hidden before:lg:block">
            Things I’ve Built
          </h2>
          <p className="subtitle text-lg">
            Welcome to my Latest Work section, where you can explore my most
            recent web development creations.
          </p>
          <Projects />
        </div>
      </div>
    </section>
  );
};

export default Portfolio;
