import React, { useState, useEffect } from "react";

// Components
import Nav from "./Nav";
import NavMobile from "./NavMobile";
import Socials from "./Socials";

// Import logo.
import logo from "../assets/img/light.png";

const Header = () => {
  const [bg, setBg] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      return window.scrollY > 50 ? setBg(true) : setBg(false);
    });
  });
  return (
    <header
      className={`${
        bg
          ? "bg-[#0a192ff8] backdrop-blur-sm h-[70px] shadow-[0_35px_60px_-15px_rgba(2,12,27,0.7)]"
          : "h-24"
      } flex items-center fixed top-0 w-full text-white z-10 transition-all duration-300 `}
    >
      <div className="container mx-auto h-full flex items-center justify-between">
        {/* logo */}
        <a href="#">
          {/* <h2 className="text-2xl text-accent-hover">Ravikant k.</h2> */}
          <img src={logo} alt="logo" className="w-11" />
        </a>
        {/* Nav */}
        <div className="hidden lg:block">
          <Nav />
        </div>
        {/* Socials */}
        <div className="hidden lg:block">
          <Socials />
        </div>
        {/* Nav mobile */}
        <div className="lg:hidden">
          <NavMobile />
        </div>
      </div>
    </header>
  );
};

export default Header;
