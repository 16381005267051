import React from "react";

// import components
import Header from "./components/Header";
import Hero from "./components/Hero";
import Hireme from "./components/Hireme";
import About from "./components/About";
import Skill from "./components/Skill";
import Portfolio from "./components/Portfolio";
import Services from "./components/Services";
import Testimonials from "./components/Testimonials";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import BackToTopBtn from "./components/BackToTopBtn";

const App = () => {
  return (
    <div>
      <Header />
      <Hero />
      <Hireme />
      <About />
      <Skill />
      <Portfolio />
      <Services />
      <Testimonials />
      <Contact />
      <Footer />
      <BackToTopBtn />
    </div>
  );
};

export default App;
