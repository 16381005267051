import React, { useEffect, useState } from "react";

// Import project data
import { projectsData } from "../data";

// import project onav data
import { projectsNav } from "../data";

// import component
import Project from "./Project";

const Projects = () => {
  const [item, setItem] = useState({ name: "all" });
  const [projects, setProjects] = useState([]);
  const [active, setActive] = useState(0);
  const [projectsToShow, setProjectsToShow] = useState(6); // Number of projects to show initially
  const projectsPerPage = 3; // Number of projects to show per "Show More" click

  useEffect(() => {
    // Get projects base on item
    if (item.name === "all") {
      setProjects(projectsData);
    } else {
      const newProjects = projectsData.filter((project) => {
        return project.category.toLowerCase() === item.name;
      });
      setProjects(newProjects);
    }
  }, [item]);

  const handleClick = (e, index) => {
    setItem({ name: e.target.textContent.toLowerCase() });
    setActive(index);
  };

  // Function to handle "Show More" button click
  const handleShowMore = () => {
    setProjectsToShow(projectsToShow + projectsPerPage);
  };
  return (
    <div>
      {/* Navbar */}
      <nav className="mb-12 max-w-xl mx-auto">
        <ul className="flex flex-row flex-wrap justify-evenly items-center text-secondary">
          {projectsNav.map((item, index) => {
            return (
              <li
                onClick={(e) => {
                  handleClick(e, index);
                }}
                className={`${
                  active === index ? "active" : ""
                } cursor-pointer capitalize m-4`}
                key={index}
              >
                {item.name}
              </li>
            );
          })}
        </ul>
      </nav>
      {/* Projects grid */}
      <section className="grid lg:grid-cols-3 gap-y-12 lg:gap-x-8 lg:gap-y-8">
        {projects.slice(0, projectsToShow).map((item) => {
          return <Project item={item} key={item.id} />;
        })}
      </section>
      {/* Show More button */}
      {projectsToShow < projects.length && (
        <div className="flex justify-center mt-4">
          <button
            className="bg-transparent mt-7 text-accent border-b-2 border-accent"
            onClick={handleShowMore}
          >
            Show More
          </button>
        </div>
      )}
    </div>
  );
};

export default Projects;
