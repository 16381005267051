import React from "react";

// Import image
import aboutImg from "../assets/img/about.png";

const About = () => {
  return (
    <section id="about" className="section bg-primary">
      {/* Left */}
      <div className="container mx-auto">
        <div className="flex flex-col xl:flex-row gap-16">
          <img
            className="object-cover h-full w-[566px] md:mx-auto lg:mx-0 rounded"
            src={aboutImg}
            alt="about"
          />

          {/* Right */}
          <div className="flex flex-col items-center text-center lg:items-start lg:text-left">
            <div className="flex flex-col">
              <h2 className="text-secondary text-3xl lg:text-4xl font-medium lg:font-extrabold mb-3 before:content-about relative before:absolute before:opacity-30 before:-top-[2rem] before:hidden before:lg:block">
                Ravikant Kumar
              </h2>
              <p className="mb-4 text-accent">Frontend Developer</p>
              {/* <hr className="mb-8 opacity-5" /> */}
              <p className="mb-8 text-lg">
                As an aspiring full-stack web developer, I currently work as a
                front-end engineer, specializing in creating visually appealing
                and user-friendly websites. I am passionate about delivering
                seamless user experiences. I am eager to expand my skills to
                encompass back-end technologies and become proficient in
                building end-to-end web solutions.
                <br />
                <br /> Proficient in WordPress and CMS: Specializing in building
                websites using WordPress and other CMS, delivering visually
                appealing and user-friendly designs.
              </p>
            </div>
            <a
              href="https://www.freelancer.in/u/ravikant1999"
              target="_blank"
              rel="noreferrer"
            >
              <button className="btn btn-md rounded bg-transparent border font-body font-normal text-accent border-accent  hover:bg-transparent mt-5 md:btn-lg transition-all">
                Freelancer Profile
              </button>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
