import React from "react";

// Import components
import TestimonialSlider from "./TestimonialSlider";

const Testimonials = () => {
  return (
    <section id="testimonials" className="section bg-primary">
      <div className="container mx-auto">
        <div className="flex flex-col items-center text-center">
          <h2 className="section-title before:content-testimonials relative before:absolute before:opacity-30 before:-top-[2rem] before:-left-64 before:hidden before:lg:block">
            Words of Appreciation
          </h2>
          <p className="subtitle text-lg">
            Welcome to our testimonial section! We are thrilled to share the
            valuable feedback and experiences of our wonderful clients.{" "}
          </p>
        </div>
        <TestimonialSlider />
      </div>
    </section>
  );
};

export default Testimonials;
