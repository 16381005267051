import React from "react";

// import skill data
import { skills } from "../data";

const Skill = () => {
  return (
    <section className="bg-tertiary py-12">
      <div className="container mx-auto">
        <div className="grid grid-cols-8 md:grid-flow-row">
          {skills.map((item, index) => {
            return (
              <div
                className="flex items-center justify-center p-2 md:p-0 lg:p-0"
                key={index}
              >
                <img src={item.image} alt="skill-icon" />
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default Skill;
