import React from "react";

// import hero image
// import heroImg from "../assets/img/ravikant_k_banner.png";

const Hero = () => {
  return (
    <section
      id="home"
      className="lg:h-[100vh] flex items-center bg-primary lg:bg-cover lg:bg-center lg:bg-no-repeat py-32 lg:py-0 overflow-hidden"
    >
      <div className="container mx-auto">
        <div className="flex items-center h-full pt-9">
          {/* Left side */}
          <div className="flex-1 flex flex-col items-start ">
            <p className="font-body tracking-wider text-lg text-accent-hover mb-[20px]">
              Hi, my name is
            </p>
            <h1 className="text-secondary font-primary text-4xl leading-[44px] md:text-6xl md:leading-tight  lg:text-7xl lg:leading-[1.2] font-extrabold md:tracking-[-2px]">
              Ravikant Kumar <br />{" "}
              <span className="text-paragraph">
                I build things for the web.
              </span>
            </h1>
            <p className="pt-4 pb-8 md:pb-12 max-w-[520px] text-lg text-left">
              Professional website developer and designer to bring your ideas to
              life. I'm Ravikant, a seasoned professional with over 3 years of
              expertise in crafting stunning websites.
            </p>
            <a
              href="https://drive.google.com/file/d/1bUdAcRH-joAr55wJU3ssfbeh1ueyX6a1/view?usp=sharing"
              target="_blank"
              rel="noreferrer"
            >
              <button className="btn btn-md rounded bg-transparent border font-body font-normal text-accent border-accent 	 hover:bg-transparent md:btn-lg transition-all">
                Download CV
              </button>
            </a>
          </div>
          {/* Right side */}
          {/* lg:flex flex-1 justify-end items-center h-full */}
          <div className="hidden">
            {/* <img src={heroImg} alt="" className="w-96" /> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
