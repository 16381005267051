import React from "react";

import { FiExternalLink, FiGithub } from "react-icons/fi";

const Project = ({ item }) => {
  return (
    <div key={item.id} className="flex flex-col items-center text-center ">
      <div className="mb-8 relative">
        <div>
          <a
            className=" flex items-center justify-center text-accent absolute top-[30px] h-8 w-8 rounded-full bg-primary right-3"
            href={item.github}
            target="_blank"
            rel="noreferrer"
          >
            <FiGithub />
          </a>
        </div>

        <a
          className="flex items-center justify-center bg-primary h-8 w-8 rounded-full text-accent absolute top-[80px] right-3"
          href={item.liveLink}
          target="_blank"
          rel="noreferrer"
        >
          <FiExternalLink />
        </a>

        <img className="rounded" src={item.image} alt="" />
      </div>
      <p className="capitalize text-accent text-sm mb-3">{item.category}</p>
      <h3 className="text-xl font-semibold capitalize mb-3">{item.name}</h3>
    </div>
  );
};

export default Project;
