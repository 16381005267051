import React from "react";
import { useForm, ValidationError } from "@formspree/react";

// Import contact data
import { contact } from "../data";

const Contact = () => {
  const [state, handleSubmit] = useForm("mzbldblv");
  if (state.succeeded) {
    return (
      <>
        <div className="section bg-primary">
          <div className="container mx-auto text-center text-accent text-xl">
            <p>
              Thank you 😊 <br />
              We have received your message and will get back to you shortly.
            </p>
          </div>
        </div>
      </>
    );
  }
  return (
    <section className="section bg-primary" id="contact">
      <div className="container mx-auto">
        {/* Section title */}
        <div className="flex flex-col items-center text-center">
          <h2 className="section-title before:content-contact relative before:absolute before:opacity-30 before:-top-7 before:-left-40 before:hidden before:lg:block">
            Contact Me
          </h2>
          <p className="subtitle text-lg">
            Feel free to reach out! Whether it's for a new project,
            collaboration, or just to say hello
          </p>
        </div>
        <div className="flex flex-col lg:gap-x-8 lg:flex-row">
          {/* Info */}
          <div className="flex flex-1 flex-col items-start space-y-8 mb-12 lg:mb-0 lg:pt-2">
            {contact.map((item, index) => {
              const { icon, title, subtitle, description } = item;
              return (
                <div className="flex flex-col lg:flex-row gap-x-4" key={index}>
                  <div className="text-accent rounded w-14 h-14 flex items-start justify-center mt-2 mb-4 lg:mb-0 text-2xl">
                    {icon}
                  </div>
                  <div>
                    <h4 className="font-body text-xl mb-1">{title}</h4>
                    <p className="mb-1">{subtitle}</p>
                    <p className="text-accent font-normal">{description}</p>
                  </div>
                </div>
              );
            })}
          </div>

          {/* Form */}
          <form
            onSubmit={handleSubmit}
            className="space-y-8 w-full max-w-[780px]"
          >
            <div className="flex gap-8">
              {/* Name */}
              <input
                className="input"
                type="text"
                id="name"
                name="name"
                placeholder="Your name"
                required
              />
              <ValidationError
                prefix="Name"
                field="name"
                errors={state.errors}
              />
              {/* Email */}
              <input
                className="input"
                type="email"
                id="email"
                name="email"
                placeholder="Your email"
                required
              />
              <ValidationError
                prefix="Email"
                field="email"
                errors={state.errors}
              />
            </div>
            {/* subject */}
            <input
              className="input"
              type="text"
              id="subject"
              name="subject"
              placeholder="Subject"
            />
            <ValidationError
              prefix="Subject"
              field="subject"
              errors={state.errors}
            />
            {/* Message */}
            <textarea
              className="textarea"
              placeholder="Your message"
              id="message"
              name="message"
            ></textarea>
            <ValidationError
              prefix="Message"
              field="message"
              errors={state.errors}
            />
            <button
              disabled={state.submitting}
              className="btn btn-lg bg-accent text-primary"
            >
              Send Message
            </button>
          </form>
        </div>
      </div>
    </section>
  );
};

export default Contact;
