import React, { useState, useEffect } from "react";

// Import link
import { Link, animateScroll as scroll } from "react-scroll";

// Import icon
import { ChevronUpIcon } from "@heroicons/react/outline";

const BackToTopBtn = () => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      return window.scrollY > 600 ? setShow(true) : setShow(false);
    });
  });

  const scrollToTop = () => {
    scroll.scrollToTop();
  };
  return (
    show && (
      <button
        onClick={() => scrollToTop()}
        className="bg-accent w-10 h-10 text-primary rounded-full fixed right-8 bottom-20 cursor-pointer flex justify-center items-center transition-all lg:right-24 lg:bottom-24"
      >
        <ChevronUpIcon className="w-5 h-5" />
      </button>
    )
  );
};

export default BackToTopBtn;
