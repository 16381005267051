import React from "react";

// import services data
import { services } from "../data";

const Services = () => {
  return (
    <section id="services" className="section bg-primary">
      <div className="container mx-auto">
        {/* Section title */}
        <div className="flex flex-col items-center text-center">
          <h2 className="section-title text-secondary text-3xl lg:text-4xl font-medium lg:font-extrabold mb-3 before:content-services relative before:absolute before:opacity-30 before:-top-[2rem] before:-left-28 before:hidden before:lg:block">
            Explore My Services
          </h2>
          <p className="subtitle text-lg">
            Welcome to my services section, where I offer top-notch web
            development solutions to bring your digital vision to life.
          </p>
        </div>
        {/* Services grid */}
        <div className="grid lg:grid-cols-4 gap-8">
          {services.map((service, index) => {
            const { icon, name, description } = service;
            return (
              <div className="bg-tertiary p-6 rounded text-center" key={index}>
                {/* Icon */}
                <div className="text-accent bg-primary p-4 mx-auto w-14 h-14 flex justify-center items-center mb-8 text-[50px] rounded-full shadow-[0_10px_10px_-5px_rgba(2,12,27,0.9)]">
                  {icon}
                </div>
                <h4 className="text-xl font-medium mb-2">{name}</h4>
                <p className="text-lg">{description}</p>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default Services;
