import React from "react";

// import brand logo
// import { brands } from "../data";

const Hireme = () => {
  return (
    <section className="min-h[150px] bg-primary flex items-center">
      <div className="container mx-auto">
        <div className="p-4 bg-tertiary shadow-[0_35px_60px_-15px_rgba(2,12,27,0.7)]  rounded flex flex-col py-9 md:p-8 md:justify-between md:flex-row items-center justify-between lg:h-full lg:flex-row lg:p-12">
          {/* Left */}
          <div className="text-center">
            <p className="font-body tracking-wider text-lg text-accent-hover mb-[20px] md:text-left lg:text-left">
              Available for work
            </p>
            <h1 className="text-secondary font-primary text-4xl leading-[44px] mb-3 md:leading-tight  md:text-left md:mb-0 lg:mb-0 lg:text-5xl lg:text-left lg:leading-[1.2] font-extrabold md:tracking-[-2px]">
              Hire Me To Build Your <br className="hidden md:block lg:block" />
              Website
            </h1>
          </div>
          {/* Right */}
          <div className="flex items-center justify-end">
            <a
              href="https://www.freelancer.in/u/ravikant1999"
              target="_blank"
              rel="noreferrer"
            >
              <button className="btn btn-md rounded bg-transparent border font-body font-normal text-accent border-accent  hover:bg-transparent mt-5 md:btn-lg transition-all">
                Let's Get Started!
              </button>
            </a>
            {/* {brands.map((item, index) => {
            return (
              <div key={index}>
                <img src={item.img} alt="" className="ml-0 md:ml-0 lg:ml-8" />
              </div>
            );
          })} */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hireme;
