import React from "react";

// Import testimonials data
import { testimonials } from "../data";

// import swiper react components
import { Swiper, SwiperSlide } from "swiper/react";

// Import swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "../swiper.css";

// Import required modules
import { Autoplay, Pagination } from "swiper";

const TestimonialSlider = () => {
  return (
    <>
      <Swiper
        pagination={{
          clickable: true,
        }}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        modules={[Autoplay, Pagination]}
        className="mySwiper"
      >
        {testimonials.map((item, index) => {
          const { authorImg, authorText, authorName, authorPosition } = item;
          return (
            <SwiperSlide key={index}>
              <div className="mb-10 flex flex-col justify-center items-center text-center gap-12 lg:gap-[50px]">
                <div className="w-[100px] h-[100px]">
                  <img
                    className="rounded-full w-[100px]"
                    src={authorImg}
                    alt="author_image"
                  />
                </div>
                <div className="flex flex-col max-w-3xl">
                  <h5 className="text-lg mb-8 italic font-normal lg:text-2xl">
                    {authorText}
                  </h5>
                  <div>
                    <p className="text-lg text-accent">{authorName}</p>
                    <p>{authorPosition}</p>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </>
  );
};

export default TestimonialSlider;
